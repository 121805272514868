import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SubmittedTrack from "../../components/SubmittedTrack";
import { useParams } from "react-router-dom";
import { deleteUser, getUserDetails } from "../../utils/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../components/DeleteModal";
import Loader from "../../components/Loader";

interface updateDetailsForm {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  affiliatedBrands: [];
  city: string;
  countryResidence: string;
  stateResidence: string
  userId: string | null;
  tracksDetails: [];
}

const Id: React.FC = () => {
  const { userId } = useParams();
  const token = sessionStorage.getItem("token");
  const [userName, setUserName] = useState<string>();
  const [isShow, setIsShow] = useState(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState<updateDetailsForm>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    affiliatedBrands: [],
    city: "",
    countryResidence: "",
    stateResidence: "",
    userId: "",
    tracksDetails: [],
  });

  useEffect(() => {
    setIsLoading(true)
    getUserDetails(userId, token).then((res) => {
      setUserName(res.list.firstName);
      setUserDetails({
        email: res.list.email,
        password: res.list.password,
        firstName: res.list.firstName,
        lastName: res.list.lastName,
        phoneNumber: res.list.phoneNumber,
        affiliatedBrands: res.list.affiliatedBrands,
        city: res.list.city || "",
        countryResidence: res.list.countryResidence || "",
        stateResidence: res?.list?.stateResidence || "",
        tracksDetails: res.list.tracksDetails,
        userId: sessionStorage.getItem("userId"),
      });
      setIsLoading(false)
    }).catch((err)=>{
      setIsLoading(false);
      toast.error(err.response.data.message);
    })
  }, [userId, token]);

  const handleDeleteUser = () => {
    setIsLoading(true)
    deleteUser(userId as string, token)
      .then((res) => {
        setIsLoading(false)
        toast.success("User deleted successfully");
        setTimeout(() => {
          navigate("/admin-dashboard");
        }, 500);
      })
      .catch((err) => {
        setIsLoading(false)
        toast.error(err.response.data.message);
      });
  };

  const navigate = useNavigate();
  return (
    <>
      <ToastContainer />
      <div className="profileContainer">
        <div className="title">
          <button
            className={`btn ${!isShow ? "active" : ""}`}
            onClick={() => {
              setIsShow(false);
            }}
            style={{ borderRadius: "10px 0 0 10px" }}
          >
            {" "}
            {userName}
          </button>
          <button
            className={`btn ${isShow ? "active" : ""}`}
            onClick={() => {
              setIsShow(true);
            }}
            style={{ borderRadius: "0 10px 10px 0" }}
          >
            {" "}
            Submitted Tracks
          </button>
        </div>
        {!isShow ? (
          <div className="layout">
            <div className="grid md:flex gap-5">
              <div className="input-container">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  value={userDetails.firstName}
                  placeholder="First Name"
                  disabled
                />
              </div>
              <div className="input-container">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  value={userDetails.lastName}
                  placeholder="Last Name"
                  disabled
                />
              </div>
            </div>

            <div className="grid md:flex gap-5">
              <div className="input-container">
                <label htmlFor="email">Email</label>
                <input
                  type="text"
                  name="email"
                  value={userDetails.email}
                  placeholder="Email"
                  disabled
                />
              </div>
              <div className="input-container">
                <label htmlFor="countryResidence">Country</label>
                <input
                  type="text"
                  name="countryResidence"
                  value={userDetails.countryResidence}
                  placeholder="Country"
                  disabled
                />
              </div>
            </div>

            <div className="grid md:flex gap-5">
              <div className="input-container">
                <label htmlFor="stateResidence">State/Province</label>
                <input
                  type="text"
                  name="stateResidence"
                  value={userDetails.stateResidence}
                  placeholder="State"
                  disabled
                />
              </div>
              <div className="input-container">
                <label htmlFor="city">City</label>
                <input
                  type="text"
                  name="city"
                  value={userDetails.city}
                  placeholder="City"
                  disabled
                />
              </div>
            </div>

            <div className="grid md:flex gap-5">
              <div className="w-full">
                <label htmlFor="">Affiliated Bands / Musical Groups</label>
                {userDetails.affiliatedBrands.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    name={`affiliatedBrands-${index}`}
                    placeholder="Affiliated Bands / Musical Groups"
                    value={value}
                  />
                ))}
              </div>
            </div>

            <div className="footer">
              <button
                className="back-btn"
                onClick={() => {
                  navigate("/admin-dashboard");
                }}
              >
                Back
              </button>
              <button
                className="delete-btn"
                onClick={() => {
                  setIsDelete(true);
                }}
              >
                Delete User
              </button>
            </div>
          </div>
        ) : (
          <>
            <SubmittedTrack data={userDetails.tracksDetails} />
          </>
        )}
      </div>
      {isDelete && (
        <DeleteModal
          onClose={() => setIsDelete(false)}
          onYes={handleDeleteUser}
          message={"Hello there, are you sure you want to remove this user?"}
        />
      )}
      {isLoading && <Loader/>}
    </>
  );
};

export default Id;
