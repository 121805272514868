import React, { useEffect, useState } from "react";
import "./style.scss";
import upload from "../../assets/icons/bUpload.svg";
import successBanner from "../../assets/Pictures/successBanner.png";
import { uploadTrack } from "../../utils/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { disclaimerText, genre, additionalLanguages } from "../../constants";
import Loader from "../../components/Loader";
import { getData } from "country-list";
import ISO6391 from "iso-639-1";
import { State } from "country-state-city";

interface uploadTrackForm {
  trackName: string;
  albumName: string;
  selectGenre: string;
  city: string;
  countryResidence: string;
  stateResidence: string;
  artistBand: string;
  languageForLyrics: string;
  disclaimer: boolean;
  userId: string | null;
}

const UploadTrack: React.FC = () => {
  const token = sessionStorage.getItem("token");
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [states, setStates] = useState<any[]>([]);
  const [isAutoFill, setIsAutoFill] = useState<boolean>(false);
  const [formData, setFormData] = useState<uploadTrackForm>({
    trackName: "",
    albumName: "",
    selectGenre: "",
    city: "",
    countryResidence: "",
    stateResidence: "",
    artistBand: "",
    languageForLyrics: "",
    disclaimer: false,
    userId: sessionStorage.getItem("userId"),
  });

  useEffect(() => {
    const isValid =
      formData.trackName &&
      formData.albumName &&
      formData.selectGenre &&
      formData.city &&
      formData.countryResidence &&
      formData.artistBand &&
      formData.languageForLyrics &&
      formData.disclaimer &&
      file;
    setIsFormValid(isValid as boolean);
  }, [formData, file]);

  useEffect(() => {
    const storedData = localStorage.getItem('AutoFill') || "";
    if (storedData) {
      const data = JSON.parse(storedData);
      if (data) {
        const selectedCountryCode = countryOptions.find(
          (country: any) => country.label === data.Country
        )?.value;

        if (selectedCountryCode) {
          const fetchedStates = State.getStatesOfCountry(selectedCountryCode).map(
            (state: any) => ({
              value: state.isoCode,
              label: state.name,
            })
          );
          setStates(fetchedStates);
          setFormData({
            ...formData,
            artistBand: data.Artist,
            city: data.City,
            countryResidence: data.Country,
            languageForLyrics: data.Language,
            stateResidence: data.State,
          });
        }
      }
    }
    else {
      setFormData({
        trackName: "",
        albumName: "",
        selectGenre: "",
        city: "",
        countryResidence: "",
        stateResidence: "",
        artistBand: "",
        languageForLyrics: "",
        disclaimer: false,
        userId: sessionStorage.getItem("userId"),
      });
      setFile(null);
    }
    setIsAutoFill(false)
  }, [isAutoFill])

  const capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : capitalize(value),
    });
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const formattedText = (value: boolean) => {
    const text = value ? disclaimerText : disclaimerText.slice(0, 503);
    return text.split("\n").map((line: any, index: number) => (
      <span key={index}>
        {line}
        <br />
      </span>
    ));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (!token) {
      toast.error("Please log in.");
      return;
    }

    if (
      !formData.albumName &&
      !formData.artistBand &&
      !formData.city &&
      !formData.countryResidence &&
      !formData.disclaimer &&
      !formData.languageForLyrics &&
      !formData.selectGenre &&
      !formData.trackName
    ) {
      toast.error("Fill all require information");
    } else {
      setIsLoading(true);
      const form = new FormData();
      if (file) {
        form.append("file", file);
      }
      const body = {
        Artist: formData.artistBand,
        City: formData.city,
        Country: formData.countryResidence,
        State: formData.stateResidence,
        Language: formData.languageForLyrics
      }
      localStorage.setItem('AutoFill', JSON.stringify(body))

      form.append("trackName", formData.trackName);
      form.append("albumName", formData.albumName);
      form.append("selectGenre", formData.selectGenre);
      form.append("city", formData.city);
      form.append("countryResidence", formData.countryResidence);
      form.append("artistBand", formData.artistBand);
      form.append("languageForLyrics", formData.languageForLyrics);
      form.append("disclaimer", formData.disclaimer.toString());
      form.append("userId", formData.userId || "");
      uploadTrack(form, token)
        .then((res) => {
          setIsLoading(false);
          toast.success("Track uploaded successfully");
          setIsSubmitted(!isSubmitted);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data.message);
        });
    }
  };

  const resetForm = () => {
    setIsAutoFill(true)
    setIsSubmitted(!isSubmitted);
  };

  const formatDate = (date: Date): string => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const countryOptions = getData().map((country: any) => ({
    value: country.code,
    label: country.name,
  }));

  const languageNames = ISO6391.getAllNames();
  const languages = Object.entries(languageNames).map(([code, name]) => ({
    code,
    name,
  }));

  additionalLanguages.forEach((lang) => {
    if (!languages.some((language) => language.name === lang)) {
      languages.push({
        code: "",
        name: lang,
      });
    }
  });

  const languagesList = languages.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  const handleCountryChange = (event: any) => {
    const selectedCountryLabel = event.target.value;
    const selectedCountryCode =
      event.target.options[event.target.selectedIndex].getAttribute(
        "data-label"
      );

    setFormData({
      ...formData,
      countryResidence: selectedCountryLabel,
      stateResidence: "",
    });

    const fetchedStates = State.getStatesOfCountry(selectedCountryCode).map(
      (state: any) => ({
        value: state.isoCode,
        label: state.name,
      })
    );
    setStates(fetchedStates);
  };

  return (
    <>
      <ToastContainer />
      {!isSubmitted ? (
        <div className="uploadTrackContainer">
          <div className="title">
            <h3>
              Submit Your Music for Worldwide Airplay on our Radio Stations!
            </h3>
            <h1>
              Share your tracks with our audience and be heard by a global
              audience!
            </h1>
          </div>

          <form className="wrapper" onSubmit={handleSubmit}>
            <label htmlFor="file-upload" className="file-input">
              <img src={upload} alt="upload" />
              Upload Your Track
            </label>
            <input
              id="file-upload"
              type="file"
              accept=".mp3"
              name="file"
              onChange={handleFileChange}
            />
            <span>Note : You can upload mp3 track only.</span>
            {file && <p className="uploaded-track">{file.name}</p>}

            <div className="layout">
              <h2>Track Information Required</h2>

              <div className="grid md:flex gap-5 mt-5">
                <input
                  type="text"
                  placeholder="Track Name *"
                  name="trackName"
                  value={formData.trackName}
                  onChange={handleChange}
                />
                <input
                  type="text"
                  placeholder="Artist / Band *"
                  name="artistBand"
                  value={formData.artistBand}
                  onChange={handleChange}
                />
              </div>

              <div className="grid md:flex gap-5 mt-5">
                <input
                  type="text"
                  placeholder="City *"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
                <select
                  name="countryResidence"
                  value={formData.countryResidence}
                  onChange={handleCountryChange}
                >
                  <option value="">Select a country *</option>
                  {countryOptions.map((ele: any, index: number) => (
                    <option key={index} value={ele.label} data-label={ele.value}>
                      {ele.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className="grid md:flex gap-5 mt-5">
                <select
                  name="stateResidence"
                  value={formData.stateResidence}
                  onChange={handleChange}
                  disabled={!formData.countryResidence}
                >
                  <option value="">Select State/Province</option>
                  {states.map((state, index) => (
                    <option key={index} value={state.label}>
                      {state.label}
                    </option>
                  ))}
                </select>
                <input
                  type="text"
                  placeholder="Album Name *"
                  name="albumName"
                  value={formData.albumName}
                  onChange={handleChange}
                />
              </div>
              <div className="grid md:flex gap-5 mt-5">
                <select
                  name="languageForLyrics"
                  value={formData.languageForLyrics}
                  onChange={handleChange}
                >
                  <option value="">Language for Lyrics *</option>
                  {languagesList.map((ele: any, index: number) => (
                    <option key={index} value={ele.name}>
                      {ele.name}
                    </option>
                  ))}
                </select>

                <select
                  name="selectGenre"
                  value={formData.selectGenre}
                  onChange={handleChange}
                >
                  <option value="">Select Genre *</option>
                  {genre.map((ele: any, index: number) => (
                    <option key={index} value={ele}>
                      {ele}
                    </option>
                  ))}
                </select>
              </div>

              <div className="disclaimer">
                <div className="heading">
                  <p>I acknowledge I have read this disclaimer</p>
                  <input
                    name="disclaimer"
                    type="checkbox"
                    checked={formData.disclaimer}
                    onChange={handleChange}
                  />
                </div>
                <div className="text">
                  <div className="text-display">
                    <div>
                      {formattedText(isExpanded)}
                      <p
                        className="toggle-btn"
                        onClick={() => {
                          setIsExpanded(!isExpanded);
                        }}
                      >
                        {isExpanded ? "Read Less" : "Read More..."}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="submit-btn"
                onClick={handleSubmit}
                disabled={!isFormValid}
                style={
                  !isFormValid ? { opacity: "0.5", cursor: "not-allowed" } : {}
                }
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="uploadTrackContainer">
          <div className="title">
            <h3>Successfully Uploaded!!!</h3>
            <h1>Congratulations you've uploaded the track.</h1>
          </div>

          <div className="wrapper success-container">
            <div className="text py-4">
              <span>
                You're track{" "}
                <span className="font-bold">
                  {file && file.name.slice(0, 15)}
                </span>
                {file && file?.name?.length > 15 && <span>...</span>} is
                successfully submitted for a review.
              </span>
              <span>{formatDate(new Date())}</span>
            </div>

            <img
              className="min-w-full"
              src={successBanner}
              alt="successBanner"
            />
            <button className="submit-btn my-5" onClick={resetForm}>
              continue
            </button>
          </div>
        </div>
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default UploadTrack;
